import { useState } from 'react';
import "./Features.css"
import Feature from './Feature/Feature';


function Features() {
    const [viewport, setViewport] = useState(document.documentElement.clientWidth);
    window.addEventListener('resize', () => {
        // viewport = document.documentElement.clientWidth;
        setViewport(document.documentElement.clientWidth);
    });
    let viewportType = `${viewport >= 992 ? "desktop" : "mobile"}/`;

    let containerClasses = `section feature-container ${viewport >= 992 ? "desktop" : "mobile"}`;
    let headerClasses = `header ${viewport >= 992 ? "desktop" : "mobile"}`;
    let featureClasses = `row features ${viewport >= 992 ? "desktop" : "mobile"}`;
    let circleClasses = `feature-red-circle ${viewport >= 992 ? "desktop" : "mobile"}`
    let mockupClasses = `mockup ${viewport >= 992 ? "desktop" : "mobile"}`;



    return <div className={containerClasses} id="features">
        <h2 className={headerClasses}>Features</h2>
        <div className="container-fluid feature-outer">
            <div className={featureClasses}>
                <Feature icon={`./images/features/${viewportType}AI-powered_icon.svg`} text={"AI-driven software system developed to automatically capture, evaluate, and manage shooting scores with precision."} />
                <Feature icon={`./images/features/${viewportType}Data transfer_icon.svg`} text={"Cloud-based data synchronization with real-time updates available on mobile apps for iOS and Android."} />
                <Feature icon={`./images/features/${viewportType}Statistics based_icon.svg`} text={"Analysis of performance data with detailed progress tracking designed specifically for athletes and coaches."} />
                <Feature icon={`./images/features/${viewportType}Unique design_icon.svg`} text={"A design crafted for individual preferences, ensuring a personalized and user-centric experience."} />
                <Feature icon={`./images/features/${viewportType}Leaderboard_icon.svg`} text={"Access to leaderboards on both local and global scales, fostering competition and engagement."} />
                <Feature icon={`./images/features/${viewportType}Social media_icon.svg`} text={"Functionality for seamless sharing of achievements and updates directly to social media platforms."} />
            </div>
        </div>
        <div className={circleClasses}> </div>
        <img className={mockupClasses} src="./images/features/transparent_mockup.webp"/>
    </div>
}


export default Features;